import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import ucsc from "../../../images/ucsc.png";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { socialmedias } from "../../data";
export default function SocialMedia() {
  return (
    <div>
      <Container fluid className="certificate-section" id="about">
        <Container>
          <Row>
            <Col
              md={12}
              className="certificate-description d-flex justify-content-start"
            >
              <Zoom left cascade>
                <h1 className="aboutme-heading">Social Media</h1>
              </Zoom>
            </Col>
            <Col ms={12} className="mt-3">
              <Row className="g-5">
              {
                socialmedias.slice(0,4).map(social => (
                  <Col md={3} className = "d-flex justify-content-center">
                    <a
                      href={social.url}
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                    >
                      <Zoom cascade>
                      {social.icon}
                      </Zoom>
                    </a>
                  </Col>
                ))
              }
              </Row>
            </Col>
          </Row>
          <div className="blog--viewAll">
            <Link to="/socialpage">
              <button className="btn btn-primary">
                View All
                <HiArrowRight className="viewArr" />
              </button>
            </Link>
          </div>
        </Container>
      </Container>
    </div>
  );
}
