import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import ucsc from "../../images/ucsc.png";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { testimonials } from "../data";
export default function Testimonial() {
  return (
    <div>
      <Container fluid className="certificate-section" id="about">
        <Container>
          <Row>
            <Col
              md={12}
              className="certificate-description"
            >
              <Zoom left cascade>
                <h1 className="aboutme-heading">Testimonial</h1>
              </Zoom>
            </Col>
            <Col ms={12} className="mt-3">
              <Row className="g-5">
              {
                testimonials.slice(0,3).map((cert, i)=>
                  <Col md={4} className="col-sm-12 col-md4">
                  <Fade bottom duration={2000} distance="20px">
                    <div className="cert-card" key = {i+"_testinominal"}>
                      <div className="content">
                        <a
                          href={cert.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="content-overlay"></div>
                          <div
                            className="cert-header"
                            style={{ backgroundColor: "#33184b" }}
                          >
                            {
                              <img
                                className="logo_img"
                                src={"/photos/"+cert.photo}
                                style={{ borderRadius:"50%" }}
                                alt={ucsc}
                              />
                            }
                          </div>
                          <div className="content-details fadeIn-top">
                            <h3
                              className="content-title"
                              style={{ color: "black", cursor: "default" }}
                            >
                              <a href={cert.link} target="_blank" rel="noopener noreferrer"
                                style={{textDecoration: "none", color: "black"}}>
                                  Contact
                              </a>
                            </h3>
                          </div>
                        </a>
                      </div>
                      <div className="cert-body">
                        <h2
                          className="cert-body-title"
                          style={{ fontWeight: 700, color: "#eb90ff" }}
                        >
                          {cert.title}
                        </h2>
                        <h3
                          className="cert-body-subtitle"
                          style={{
                            color: "#fbd9ad",
                            marginBottom: "0px",
                          }}
                        >
                          {cert.review}
                        </h3>
                      </div>
                    </div>
                  </Fade>
              </Col>)
              }
              </Row>
            </Col>
          </Row>
          <div className="blog--viewAll">
            <Link to="/certificatepage">
              <button className="btn btn-primary">
                View All
                <HiArrowRight className="viewArr" />
              </button>
            </Link>
          </div>
        </Container>
      </Container>
    </div>
  );
}
