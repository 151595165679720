import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
// import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
const About = () => {
  return (
    <div>
      <Container>
        {/* <Slide left > */}
        <Row className="mt-5">
          {/* <Col md={5}>
            <Slide left>
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "90%" }}
                loop
                autoplay
              ></lottie-player>
            </Slide>
          </Col> */}

          <Col md={7}>
            <Fade duration={3000}>
                <p className="home-about-body">
                    I am a game programmer with 10 years of experience, primarily working with <span className="h5 purple"><b>Unity</b></span> and <span className="h5 purple"><b>Godot</b></span> as my main engines.

                    <br /><br />

                    A significant portion of my career has been dedicated to working on four <span className="h5 purple"><b>crypto metaverse </b></span>projects, where I’ve experienced both failure and success, gaining valuable insights from both. Additionally, I’ve developed indie and mobile games, honing my skills across diverse platforms.

                    <br /><br />
                    
                    My expertise lies in <span className="h5 purple"><b>gameplay engineering</b></span>, where I’ve focused on core gameplay mechanics. My work on economic and financial systems in metaverse projects sets me apart. I’ve also implemented various gameplay systems such as combat systems and progression systems, among others.
 
                    <br /><br />
                    On the networking side, I have extensive experience in real-time multiplayer systems. I’ve developed <span className="h5 purple"><b>multiplayer networking</b></span> systems for a real-time spaceship strategy game with over 2,000 moving objects. I’ve also contributed to projects that required real-time multiplayer synchronization and physics synchronization, utilizing technologies like Photon PUN, Fusion, Quantum, PlayFab, and Colyseus.
                    <br /><br />
                    Lately, I’ve recognized the future of gaming in <span className="h5 purple"><b>AI</b></span> and have shifted my focus to creating a new game engine that integrates <span className="h5 purple"><b>AI</b></span>, <span className="h5 purple"><b>Machine Learning</b></span>, and deep learning technologies. My current project involves building a  <span className="h5 purple"><b>game engine</b></span> in <span className="h5 purple"><b>Godot</b></span> that’s designed to let AI create game content in a well-modularized environment, enabling the AI to generate unexpected and innovative game experiences.
                    <br /><br />
                    I am passionate about connecting with others who share a deep love for gaming and a forward-thinking approach to where the industry is headed.
                </p>
            </Fade>
          </Col>
          <Col md={5}>
            <p className="home-about-body" style={{paddingLeft:"40px"}}>
                <h3 className="Red">
                      {" "}
                      Game Engines
                </h3>
                Unity, Godot, Cocos Creator, Phaser, Three.js, Gamemaker, Minetest, Divinity
                <br /><br />

                <h3 className="Red">
                      {" "}
                      Languages
                </h3>
                C, C++, C#,  Lua, Java, Osiris, Python, Javascript, Typescript, HTML, CSS
                <br /><br />

                <h3 className="Red">
                      {" "}
                      Blockchain SDKs
                </h3>
                Thirdweb, Nethereum, Tezos, Enjin, Moralis, Hive, Elysium 
                <br /><br />

                <h3 className="Red">
                      {" "}
                      Networking tools
                </h3>
                Photon (PUN, Quantum, Fusion, Realtime, Bolt, Server), Mirror, PlayFab, Netcode, Colyseus, EOS, Steamworks, GameSparks, Socket.IO, Readyplayerme, replica
                <br /><br />

                <h3 className="Red">
                      {" "}
                      Game Genres
                </h3>
                Metaverse, Action, RPG, FPS, Strategy, Sports, Racing, Puzzle, Fighting, Survival, MOBA, MMORPG, Card, Betting, Turnbased, Gridbased, Roguelite, Casual
                <br /><br />
            </p>
          </Col>
        </Row>
        {/* </Slide> */}
      </Container>
    </div>
  );
};

export default About;
