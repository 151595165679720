import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {socialLinks} from "../data"
import { username } from "../data";
function Footer({locationData}) {
  let date = new Date();
  let year = date.getFullYear();


  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by {username}</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} {username}</h3>
        </Col>
        <Col md="4" className="footer-body">
        {
          locationData.isRestricted || locationData.isvpn ? (
              null
            ) :(
                <ul className="footer-icons">
                  {
                    
                    socialLinks.map((social)=>locationData.isRestricted?(null):(
                      <li className="social-icons">
                        <a
                          href={social.url}
                          style={{ color: "white" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {social.icon}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              )
        }
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
