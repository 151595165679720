import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import Particle from "../../Particle";

import {socialmedias} from "../../components/data"
import {sendPanelName, sendSocialName} from "../../utils"
export default function SocialPage({locationData}) {

  useEffect(()=>{
    sendPanelName(locationData, "SocialPage")
  },[])

  return (
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        <Container className="home-content">
          <div
            className="d-flex justify-content-center"
            width="100%"
            style={{ backgroundColor: "#fbd9ad" }}
          >
            <Zoom left cascade>
              <h1 style={{ color: "rgb(134 61 176" }}>Socials</h1>
            </Zoom>
          </div>
          <div>
            <div>
              <Container fluid className="certificate-section" id="about">
                <Container>
                  <Row>
                    <Col md={12} className="mt-5">
                      <Row className="g-5">
                        {
                          locationData.isRestricted || locationData.isvpn ? null : socialmedias.map(social => (
                            <Col md={3} className = "d-flex justify-content-center">
                              <a
                                href={social.url}
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour  home-social-icons"
                                onClick={()=>sendSocialName(locationData, social.platform)}
                              >
                                <Zoom cascade>
                                {social.icon}
                                </Zoom>
                              </a>
                            </Col>
                          ))
                        }
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
          <div className="blog--viewAll"></div>
        </Container>
      </Container>
    </section>
  );
}
