import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import data from "../data"

import {sendProjectName} from "../../utils"

export default function Projects({locationData}) {

  const [duration,setDuration] = useState(0)

  const handleMouseOver = (project_name)=>{
      // setDuration(Date.now())
  }
  const handleMouseLeave = (project_name)=>{
      // let during_time = Math.ceil((Date.now() - duration) / 1000)
      // sendProjectName(locationData, project_name, during_time)
  }
 

  return (
    <div>
      <Container fluid className="certificate-section" id="about">
        <Container>
          <Row>
            <Col
              md={12}
              className="certificate-description d-flex justify-content-start"
            >
              <Zoom left cascade>
                <h1 className="aboutme-heading">Projects</h1>
              </Zoom>
            </Col>
            {
              data.slice(0,4).map((node,i)=>(<Col md={3}>
              <Fade bottom>
                <div
                  key={i+"_small_project"}
                  className="singleProject"
                  style={{
                    backgroundColor: "rgb(142 70 186 / 31%)",
                    border: "1px solid",
                    padding:"10px 0px 0px 0px"
                  }}
                  onMouseEnter={()=>handleMouseOver(node.title)}
                  onMouseLeave={()=>handleMouseLeave(node.title)}
                >
                  <div className="projectContent">
                    <h5 id={node.title} style={{ color: "#fbd9ad" }}>
                      {node.title}
                    </h5>
                    <img src={node.img} alt={node.img} />
                    <div className="project--showcaseBtn">
                      <a
                        href={
                          node.link
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={"iconBtn"}
                        aria-labelledby={`code`}
                      >
                        <FaSearch
                          className={"icon"}
                          aria-label="Code"
                        />
                      </a>
                    </div>
                  </div>
                  <h6>
                    <p
                      className="project--desc"
                      style={{
                        background: "#fbd9ad",
                        color: "#b061df",
                        fontWeight: 600,
                      }}
                    >
                      {node.description}
                    </p>
                  </h6>
                </div>
              </Fade>
              </Col>))
            }
          </Row>
          <div className="blog--viewAll">
            <Link to="/projectspage">
              <button className="btn btn-primary">
                View All
                <HiArrowRight className="viewArr" />
              </button>
            </Link>
          </div>
        </Container>
      </Container>
    </div>
  );
}
