import React from 'react'
import Particle from '../../Particle'
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import {FaTwitter} from "react-icons/fa";
export default function Notfound() {
    return (
        <section className="home-section">
            <Container fluid id="home" >
                <Particle />
                <Container className="home-content d-flex justify-content-center align-items-center comingsoonclass">
                <Row>
                <Col>
                    <Row className="g-5">
                        <Col md={5} className = "d-flex justify-content-center">
                            <a
                            href={"https://twitter.com/DerekMillerDev"}
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons"
                            >
                            <Zoom cascade>
                                <FaTwitter/>
                            </Zoom>
                            </a>
                        </Col>
                        <p>There seems to be an issue with this website. Please visit my Twitter account and check the link to another website there.</p>
                    </Row>
                </Col>
                </Row>
                </Container>
            </Container>
        </section>
    )
}
